import React from "react";
import "./Markers.scss";
import YellowPinIcon from "../../../YellowPinIcon";
import GreenPinIcon from "../../../GreenPinIcon";

export const Markers = ({ count, type, onOpenStateChanged }) => {
  return (
    <>
      {count != 1 ? (
        <button
          onClick={() => onOpenStateChanged?.()}
          className={`markersButton ${"green-pin"}`}
        >
          {count}
        </button>
      ) : count == 1 && type == "vattenfall" ? (
        <>
          <YellowPinIcon onClick={() => onOpenStateChanged?.()} />
        </>
      ) : (
        <>
          <GreenPinIcon onClick={() => onOpenStateChanged?.()} />
        </>
      )}
    </>
  );
};
