import React from "react";
import "./LeadCard.scss";
import CloseIcon from "../../Calendar/closeIcon";

const LeadCard = ({ lead, setOpenMarker }) => {
  const closeModel = () => {
    setOpenMarker(null);
  };

  const userInfo = JSON.parse(localStorage.getItem("userinfo"));

  return (
    <div className="lead-card">
      <div className="close-icon-align">
        {" "}
        <CloseIcon stroke={"#000"} onClick={closeModel} />
      </div>
      <div className="lead-card-header">
        <div className="detailes">
          <p>
            <strong>Projekt :</strong>{" "}
          </p>
          <span>
            {" "}
            {lead?.funnel_type != "straper"
              ? lead?.funnel_type?.charAt(0).toUpperCase() +
                String(lead?.funnel_type).slice(1)
              : "WattFox"}
          </span>
        </div>

        {userInfo?.userId == lead?.userId && (
          <>
            <div className="detailes">
              <p>
                {" "}
                <strong>Kunde :</strong>
              </p>
              <span>
                {lead?.name ? lead?.name + " " + lead?.nachname : "-"}
              </span>
            </div>

            <div className="detailes">
              <p>
                {" "}
                <strong>Adresse :</strong>
              </p>
              <span>
                {lead?.strabe + " " + lead?.location + " " + lead?.pLZ}
              </span>{" "}
            </div>
            <div className="detailes">
              <p>
                {" "}
                <strong>User :</strong>
              </p>
              <span>{lead?.benutzername ?? "-"}</span>{" "}
            </div>
            <div className="detailes">
              <p>
                {" "}
                <strong>Lead Status :</strong>
              </p>
              <span>{lead?.status ?? "-"}</span>{" "}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LeadCard;
